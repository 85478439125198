import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class GoogleSigninService {
  
    private loaded: boolean = false;
  
    public loadScript(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (this.loaded) {
                resolve();
                return;
            }
    
            const script = document.createElement('script');
            script.src = 'https://accounts.google.com/gsi/client';
            script.onload = () => {
                this.loaded = true;
                resolve();
                return;
            };
            script.onerror = (error) => {
                reject(error);
            };

            document.body.appendChild(script);
        });
    }

    public async initialize(onLogin: Function) {
        await this.loadScript();

        window['google'].accounts.id.initialize({
            client_id: environment.socialiteApiKey,
            callback: onLogin,
        });
    }

    public async renderButton(parent: HTMLElement, onLogin: Function): Promise<void> {
        console.log('parent', parent);
        await this.initialize(onLogin);
        window['google'].accounts.id.renderButton(
            parent, {
                text: 'signin',
                shape: 'pill',
            }
        );
    }

    public async logout(email: string): Promise<void> {
        await this.initialize(() => {});
        return new Promise<void>((resolve) => {
            window['google'].accounts.id.revoke(email, () => {
                resolve();
            });
        });
    }
}