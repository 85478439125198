<app-listado-filtrado [dataSource]="dataSource">
    <titulo>Oficinas</titulo>
    <botones>
        <button mat-flat-button color="primary" routerLink="agregar">Agregar</button>
    </botones>
    <filtros fxLayout="column">
        <app-search-input fxFlex="100" [(value)]="dataSource.filtros.busqueda" (change)="dataSource.refreshData()" (click)="$event.stopPropagation()"></app-search-input>
    </filtros>
    <contenido>
        <div class="table-sticky-wrapper">
            <table mat-table class="full-width-table" matSort aria-label="Elements" [dataSource]="dataSource" style="min-width: 600px;">
                
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef width="80px">Id</th>
                    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                </ng-container>
    
                <ng-container matColumnDef="nombre">
                    <th mat-header-cell *matHeaderCellDef>Nombre</th>
                    <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
                </ng-container>
    
                <ng-container matColumnDef="oficina_madre">
                    <th mat-header-cell *matHeaderCellDef>Oficina Madre</th>
                    <td mat-cell *matCellDef="let element"> {{element.oficina_madre_nombre}} </td> 
                </ng-container>
    
                <ng-container matColumnDef="_acciones" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef width="80px">Acciones</th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button color="primary" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{element:element}" >
                            <mat-icon aria-hidden="false">more_vert</mat-icon>
                        </button>
                    </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <mat-paginator [length]="dataSource.total"
            [pageSize]="dataSource.limit"
            (page)="dataSource.setPageIndex($event.pageIndex)">
        </mat-paginator>
    
        <mat-menu #menu="matMenu">
            <ng-template matMenuContent let-aliasMenuItems="element">
                <button mat-menu-item routerLink="/app/oficinas/{{aliasMenuItems.id}}">Editar</button>
                <button mat-menu-item (click)="eliminar(aliasMenuItems.id)">Eliminar</button>
            </ng-template>
        </mat-menu>
    </contenido>
</app-listado-filtrado>