export const environment = 
{
    production: true,
    apiUrl: 'https://api.mercado.negociosdegranos.com.ar/api/v1',
    loginEndPoint: '/auth:google',
    logoutEndPoint: '/auth:logout',
    socialiteApiKey: '438381946364-2n8pdlbobmbmc73h83kn6ibkp7q3mvi0.apps.googleusercontent.com',
    googleApiKey: '',
    erpUrl: 'https://nego.oprcer.com.ar',
    erpV2Url: 'https://www.ngranos.com.ar/NDG_BZ/'
}