<mat-form-field class="example-full-width" appearance="outline" [class.error-field]="error || formGroup.get(nameFormField).errors" [class.error-focus-field]="inputFocus && (error || formGroup.get(nameFormField).errors)">
    <mat-label>{{label}}</mat-label>
    <input type="text"
        #inputAutocomplete
        matInput
        [formControl]="autocompleteControl"
        [matAutocomplete]="auto"
        (focusout)="onFocusOutEvent($event)"
        (focus)="onFocusEvent($event)"
        (keydown)="onKeyDown($event)"
    >
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngFor="let row of opcionesFiltradas | async" [value]="obtenerTexto(row)" (onSelectionChange)="seleccionarOpcion(obtenerValue(row))">
            {{obtenerTexto(row)}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
<ng-container *ngIf="nameFormField">
    <div *ngIf="formGroup.get(nameFormField).errors" class="mensaje-error">{{formGroup.get(nameFormField).errors.mensajeError}}</div>
    <div *ngIf="!formGroup.get(nameFormField).errors && error" class="mensaje-error">{{error}}</div>
</ng-container>