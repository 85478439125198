<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" fxLayout.xs="column" fxLayoutAlign.xs="start stretch">
    <mat-form-field appearance="outline">
        <mat-label>{{titulo}}</mat-label>
        <input matInput (keyup)="onSearch($event)" [disabled]="disabled"/>
    </mat-form-field>
    <div style="color:red;" *ngIf="error">{{error}}</div>
</div>

<div *ngIf="resultados.length>0" class="mat-form-field-wrapper">
    <mat-selection-list #shoes [multiple]="false">
        <mat-list-option *ngFor="let row of resultados" [value]="row.place_id"(click)="onClickResultado(row.place_id)">
            {{row.description}}
        </mat-list-option>
    </mat-selection-list>
</div>

<div class="mat-form-field-wrapper">
    <img class="google-map" *ngIf="url" [src]="url" />
</div>

<p *ngIf="direccion" class="direccion">{{direccion}}</p>