import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';
import { MessagesService } from 'src/app/shared/services/messages.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    public spinner: boolean = false;
    public message: string = '';

    public constructor(
        private authService: AuthService,
        private messagesService: MessagesService,
        private cdr: ChangeDetectorRef,
    ) { }

    public ngOnInit() {
    }

    public async onGotCredential(token: string) {
        try {
            this.spinner = true;
            this.cdr.detectChanges();
            await this.authService.login(token);

        } catch (e) {
            this.message = e.error.message;
            this.cdr.detectChanges();
        } finally {
            this.spinner = false;
            this.cdr.detectChanges();
        }
    }

    public closeMessage() {
        this.message = '';
        this.cdr.detectChanges();
    }

}
