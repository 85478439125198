import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { GoogleSigninService } from '../google-signin.service';

@Component({
    selector: 'app-login-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

    @Output()
    public onGotCredential: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('buttonHolder', {static: true, read: ElementRef})
    private buttonHolder: ElementRef;

    constructor(
        private signinService: GoogleSigninService,
    ) { }

    public ngOnInit() {
        this.setupSignIn();
    }

    public setupSignIn() {
        let holder = this.buttonHolder.nativeElement;
        this.signinService.renderButton(holder, (data: any) => {
            this.onGotCredential.emit(data.credential);
        });
    }

}
