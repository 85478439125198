<app-listado-filtrado [dataSource]="dataSource" (onClearFilters)="onClearFilters()">
		<titulo>Panel de mercado</titulo>
		<botones>
				<button mat-flat-button routerLink="/app/mercado/panel/agregarPosicion" color="primary">Agregar</button>
		</botones>
		<filtros fxLayout="column">
		
		<mat-form-field appearance="legacy" >
			<mat-label>Tipo de posición</mat-label>
			<mat-select multiple [(ngModel)]="filtroTipoPosicion" (selectionChange)="selecetionChangeMultiple($event,'tipo_posicion')">
				<mat-option value="" >TODAS</mat-option>
				<mat-option value="posicion_excepcional">Posición excepcional</mat-option>
				<mat-option value="a_trabajar">Posición a trabajar</mat-option>
				<mat-option value="volumen_limitado">Volumen limitado</mat-option>
			</mat-select>
		</mat-form-field>

		<app-chips-input
            [title]="'Comprador'"
            [labelColumn]="'razon_social'"
            [dataUrl]="'/clientes/empresas'"
            [(selected)]="filtros.empresa_id"
            (selectionChanged)="refreshList()"
        ></app-chips-input>
		
		<mat-form-field appearance="legacy" >
			<mat-label>Productos</mat-label>
			<mat-select multiple [(ngModel)]="filtroProductos" (selectionChange)="selecetionChangeMultiple($event,'producto_id')">
			<mat-option value="">TODOS</mat-option>
			<mat-option *ngFor="let row of productos" [value]="row.id">{{row.nombre}}</mat-option>
			</mat-select>
		</mat-form-field>

        <mat-form-field appearance="legacy" >
			<mat-label>Puertos</mat-label>
			<mat-select multiple [(ngModel)]="filtroPuertos" (selectionChange)="selecetionChangeMultiple($event,'puerto_id')">
				<mat-option value="" >TODOS</mat-option>
				<mat-option *ngFor="let row of puertos" [value]="row.id">{{row.nombre}}</mat-option>
			</mat-select>
        </mat-form-field>

		<mat-form-field appearance="legacy" >
			<mat-label>Entrega</mat-label>
			<mat-select multiple [(ngModel)]="filtroEntrega" (selectionChange)="selecetionChangeMultiple($event,'entrega')">
				<mat-option value="">TODOS</mat-option>
				<mat-option value="disponible">Disponible</mat-option>
				<mat-option value="limit">Limit</mat-option>
				<mat-option value="contractual">Contractual</mat-option>
				<mat-option value="forward">Forward</mat-option>
			</mat-select>
		  </mat-form-field>

        <mat-form-field appearance="legacy" >
          <mat-label>Calidad</mat-label>
          <mat-select multiple [(ngModel)]="filtroCalidad" (selectionChange)="selecetionChangeMultiple($event,'calidad_id')">
            <mat-option value="" >TODAS</mat-option>
            <mat-option *ngFor="let row of calidades" [value]="row.id">{{row.nombre}}</mat-option>
          </mat-select>
        </mat-form-field>

		<mat-form-field appearance="legacy" >
			<mat-label>Forma de pago</mat-label>
			<mat-select multiple [(ngModel)]="filtroFormaPago" (selectionChange)="selecetionChangeMultiple($event,'condicion_pago_id')">
			  <mat-option value="" >TODAS</mat-option>
			  <mat-option *ngFor="let row of formasPago" [value]="row.id">{{row.descripcion}}</mat-option>
			</mat-select>
		</mat-form-field>

        <mat-form-field appearance="legacy" >
          <mat-label>Cosecha</mat-label>
          <mat-select multiple [(ngModel)]="filtroCosecha" (selectionChange)="selecetionChangeMultiple($event,'cosecha_id')">
            <mat-option value="" >TODAS</mat-option>
            <mat-option *ngFor="let row of cosechas" [value]="row.id">{{row.descripcion}}</mat-option>
          </mat-select>
        </mat-form-field>

		<mat-form-field appearance="legacy">
			<mat-label>Tipo de destino</mat-label>
			<mat-select [(ngModel)]="dataSource.filtros.tipo" (selectionChange)="dataSource.refreshData()">
					<mat-option value="">TODAS</mat-option>
					<mat-option value="exportacion">Exportación</mat-option>
					<mat-option value="consumos">Consumos</mat-option>
			</mat-select>
		</mat-form-field>

		</filtros>
		<contenido>
				<div class="table-sticky-wrapper">
						<table mat-table class="full-width-table" matSort aria-label="Elements" [dataSource]="dataSource" matSort>
							<ng-container *ngFor="let column of columns" matColumnDef="{{column.name}}" [stickyEnd]="column.type==='menu'">
								<th
									mat-header-cell
									*matHeaderCellDef
									mat-sort-header
									[ngStyle]="{'text-align': column.align, width: column.width}"
								>{{column.title}}</th>
								<td class="celda" mat-cell *matCellDef="let row" [ngStyle]="{'text-align': column.align}" [attr.data-deExportacion]="getEstadoPosicion(row)">
									<ng-container *ngIf="column.type === 'figure'">
										<div fxLayout="row" style="height:25px;">
											<div *ngIf="posicionExcepcional(row)" style="flex-grow: 1;" id="rectanguloVerde"   ></div>
											<div *ngIf="volumenLimitado(row)"     style="flex-grow: 1;" id="rectanguloRojo"    ></div>
											<div *ngIf="aTrabajar(row)"           style="flex-grow: 1;" id="rectanguloAmarillo"></div>
										</div>
									</ng-container>
									<ng-container *ngIf="column.type==='custom'">
										<div style="margin: 5px;">
											<mat-chip-list >
												<mat-chip *ngIf="row.empresas.length > 1" matTooltip="{{concatenaCompradores(row.empresas)}}">Varios</mat-chip>
												<mat-chip *ngIf="row.empresas.length == 1">{{obtenerNombreComprador(row.empresas[0])}}</mat-chip>
											</mat-chip-list>
										</div>
									</ng-container>
									<ng-container *ngIf="column.type==='text'||column.type===undefined">
										{{column.valueFn(row) || row[column.name] || null}}
									</ng-container>
									<ng-container *ngIf="column.type==='menu'">
											<button mat-icon-button color="primary" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{row:row}" >
													<mat-icon aria-hidden="false">more_vert</mat-icon>
											</button>
									</ng-container>
								</td>
							</ng-container>
							<tr mat-header-row *matHeaderRowDef="columnsToShow"></tr>
							<tr mat-row *matRowDef="let row; columns: columnsToShow;"></tr>
						</table>
				</div>

				<mat-menu #menu="matMenu">
						<ng-template matMenuContent let-aliasMenuItems="row">
								<button mat-menu-item routerLink="/app/mercado/ordenes/agregar/{{aliasMenuItems.posiciones[0].id}}">Crear Orden</button>
								<button mat-menu-item routerLink="/app/mercado/panel/gestionar-ofertas/{{aliasMenuItems.clave}}">Gestionar ofertas</button>
								<button mat-menu-item routerLink="/app/mercado/posiciones/copiar/{{aliasMenuItems.posiciones[0].id}}">Copiar</button>
								<button mat-menu-item *ngIf="puedeDenunciarPosicion(aliasMenuItems)" (click)="denunciarPosiciones(aliasMenuItems.posiciones)">Denunciar</button>
								<button mat-menu-item (click)="retirarPosicion(aliasMenuItems.posiciones, aliasMenuItems.moneda)">Retirar</button>
								<button mat-menu-item (click)="eliminarPosicion(aliasMenuItems.posiciones, aliasMenuItems.moneda)">Eliminar</button>
            			</ng-template>
				</mat-menu>
		</contenido>
</app-listado-filtrado>
