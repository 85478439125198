import { Component      } from '@angular/core';
import { EventEmitter   } from '@angular/core';
import { FormControl    } from '@angular/forms';
import { FormGroup      } from '@angular/forms';
import { Input          } from '@angular/core';
import { map            } from 'rxjs/operators';
import { Observable     } from 'rxjs';
import { OnInit         } from '@angular/core';
import { Output         } from '@angular/core';
import { startWith      } from 'rxjs/operators';

@Component({
    selector    : 'app-input-autocomplete',
    templateUrl : './input-autocomplete.component.html',
    styleUrls   : ['./input-autocomplete.component.scss']
})
export class InputAutocompleteComponent implements OnInit {

    constructor() { }

    public autocompleteControl = new FormControl('');
    public opcionesFiltradas : Observable<Array<any>>;

    @Input()
    public label! : string;

    @Input()
    public opciones : Array<any>;

    @Input()
    public propiedadValue : string = '';

    @Input()
    public propiedadAFiltrar : string = '';

    @Input()
    public propiedadTexto : string = '';

    @Input()
    public formGroup! : FormGroup;

    @Input()
    public nameFormField! : string;

    @Input()
    public required : boolean = false;

    public error : string = '';

    @Output() 
    public valueChange : EventEmitter<any> = new EventEmitter<any>();

    public opcionSeleccionada : string = '';

    public inputFocus : boolean = false;
    public auxInputFocus : boolean = false;


    ngOnInit(): void {
        this.opcionesFiltradas = this.autocompleteControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value || '')),
        );

        if(this.required){
            this.label = this.label + ' *';
        }

        if(this.nameFormField && this.formGroup.get(this.nameFormField).value){
            if(this.propiedadValue){
                this.opciones = this.opciones.filter((option: any) => option[this.propiedadValue].includes(this.formGroup.get(this.nameFormField).value));
                this.autocompleteControl.setValue(this.obtenerTexto(this.opciones[0]));
                this.opcionSeleccionada = this.opciones[0];

                
            }
            else{
                let formValue = this.formGroup.get(this.nameFormField).value;
                this.autocompleteControl.setValue(formValue);
                this.opcionSeleccionada = formValue;
            }
        }
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        if(this.propiedadAFiltrar === ''){
            return this.opciones.filter((option: any) => option.toLowerCase().includes(filterValue));
        }
        return this.opciones.filter((option: any) => option[this.propiedadAFiltrar].toLowerCase().includes(filterValue));
    }

    public obtenerValue(row : any){
        if(this.propiedadValue === ''){
            return row;
        }
        else return row[this.propiedadValue];
    }

    public obtenerTexto(row : any){
        if(this.propiedadTexto === ''){
            return row;
        }
        return row[this.propiedadTexto];
    }

    public seleccionarOpcion($event : any) : void {
        this.auxInputFocus = true;
        setTimeout(()=> this.auxInputFocus = false, 100);
        this.opcionSeleccionada = $event;
        if(this.nameFormField && $event){
            this.formGroup.get(this.nameFormField).setValue($event);
            this.valueChange.emit($event);
            return;
        }
        this.valueChange.emit($event);
    }

    public onFocusOutEvent($event){
        
            this.inputFocus = false;
            if(this.required && !this.formGroup.get(this.nameFormField).value){
                this.error =  'Obligatorio';
            }
            else{
                this.error = '';
            }
        
        
    }

    public onFocusEvent($event){
        this.inputFocus = true;
        if(this.required){
            this.error =  '';
        }
    }

    public onKeyDown($event : any) : void {
        let key = $event.key;
        if(key === 'Backspace' && this.opcionSeleccionada){
            this.opcionSeleccionada = '';
            this.autocompleteControl.setValue('');
            this._filter('');
            this.formGroup.get(this.nameFormField).setValue('');
            this.valueChange.emit('');
        }
    }
    

}
