<section>
    <div fxLayout="column"
        fxLayoutAlign="center center" 
        fxLayoutGap="20px"
        fxFlexFill>
        <div style="width: 100%;">
            <div fxLayout="row" fxLayoutAlign="center center">
                <div fxFlex="20" fxFlex.xs="50">
                    <img src="/assets/images/logo_NDG.svg" alt="logo" id="logo">
                </div>
            </div>
        </div>
        <div>
            <app-login-button *ngIf="!spinner" (onGotCredential)="onGotCredential($event)"></app-login-button>
            <spinner *ngIf="spinner"></spinner>
        </div>
    </div>
</section>
<div *ngIf="message" class="message">
    <div class="container">
        <div class="texto" [innerHTML]="message"></div>
        <button mat-button (click)="closeMessage()">Cerrar</button>
    </div>
</div>