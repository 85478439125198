import { Component } from '@angular/core';
import { OnInit    } from '@angular/core';

@Component({
	selector    : 'spinner',
	templateUrl : './spinner.component.html',
	styleUrls   : ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
