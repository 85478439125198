import { Component    } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Input        } from '@angular/core';
import { OnInit       } from '@angular/core';
import { Output       } from '@angular/core';


@Component({
    selector    : 'app-select-multiple-input',
    templateUrl : './select-multiple-input.component.html',
    styleUrls   : ['./select-multiple-input.component.scss']
})
export class SelectMultipleInputComponent implements OnInit {

    constructor() { }

    @Input()
    public nombreCampo : string = '';

    @Input()
    public value : Array<any> = [];

    @Input()
    public opciones : Array<any> = [];

    @Output() 
    valueChange : EventEmitter<any> = new EventEmitter<any>();
   
    private valueAnterior! : Array<any>;

    ngOnInit(): void {

    }

    public selectChange() {
        if(this.value.includes('')){
            if(!this.valueAnterior){
                this.value = [''];
            }
            else{
                if(this.valueAnterior.includes('')){
                    this.value = [this.value[1]];
                }
                else{
                    this.value = [''];
                }
            }
        }
        this.valueChange.emit(this.value);
        this.valueAnterior = this.value;
    }
}
