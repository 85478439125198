<app-section-title>
  {{titulo}}
</app-section-title>

<form [formGroup]="form" fxLayout="column" fxLayoutAlign="start stretch">
    <div fxLayout.lg="row" fxLayout.xs="column" fxLayoutGap="10px">
        <mat-form-field appearance="fill" fxFlex.lg="200" fxFlex.xs="200">
            <mat-label>Posiciones</mat-label>
            <mat-select formControlName="posicion_id">
                <mat-option *ngFor="let row of data.posiciones" [value]="row.id">{{infoPosicion(row)}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="15px">
      <button mat-flat-button color="primary" (click)="aceptar()">
          Aceptar
      </button>
      <button mat-flat-button (click)="cancelar()">
          Cancelar
      </button>
  </div>
</form>

