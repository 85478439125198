<app-form-section>    
    <app-section-title>
        {{title}}
    </app-section-title>
    <form [formGroup]="form" fxLayout="column" fxLayoutAlign="start stretch">
        <mat-form-field appearance="fill">
            <mat-label>Nombre</mat-label>
            <input formControlName="nombre" matInput required />
            <mat-error *ngIf="error('nombre')">{{error('nombre')}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Oficina madre</mat-label>
            <mat-select formControlName="oficina_madre_id" >
                <mat-option [value]="null"><b>Ninguna</b></mat-option>
                <mat-option *ngFor="let row of oficinas$ | async" [value]="row.id">
                    {{row.nombre}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="error('oficina_madre_id')">{{error('nombre')}}</mat-error>
        </mat-form-field>
        <div fxLayout="row" fxLayoutGap="15px">
            <button mat-flat-button color="primary" (click)="guardar()">
                Guardar
            </button>
            <button mat-flat-button routerLink="/app/oficinas">
                Cancelar
            </button>
        </div>
    </form>
</app-form-section>

